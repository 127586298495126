import { Block } from "common/types/graphql"
import { TextLink } from "common/components/TextLink"
import { isValidUrl } from "common/lib/isValidUrl"
import { BlocksRenderer } from "../BlocksRenderer"

export interface LinkProps {
  blocks?: Block[]
  text?: string
  url: string
}

export const Link = ({ blocks, text, url }: LinkProps) => (
  <TextLink href={isValidUrl(url) ? url : undefined}>
    {text}
    {blocks && <BlocksRenderer blocks={blocks} />}
  </TextLink>
)
