import type { PropsWithChildren, AnchorHTMLAttributes } from "react"
import clsx from "classnames"

interface TextLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "className"> {}

export const TextLink = ({ children, ...attributes }: PropsWithChildren<TextLinkProps>) => (
  <a
    {...attributes}
    className={clsx("underline underline-offset-2 visited:text-gray-700 hover:text-indigo-600")}
  >
    {children}
  </a>
)
